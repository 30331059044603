import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api'; // Import the centralized Axios instance
import { Table } from './common/Table/Table';
import newslettersStyles from './Newsletters.module.css';
import editorStyles from './NewsletterEditor.module.css';

function Newsletters() {
  const [newsletters, setNewsletters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNewsletters();
  }, []);

  const fetchNewsletters = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/newsletters');
      console.log('Fetched newsletters:', response.data.data); // Debug log
      setNewsletters(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching newsletters:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch newsletters: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };

  const handleAddNewsletter = () => {
    navigate('/dashboard/new-newsletter');
  };

  const handleRowClick = (newsletter) => {
    navigate(`/dashboard/newsletter-editor/${newsletter._id}`);
  };

  if (loading) return <div className={newslettersStyles.loading}>Loading...</div>;
  if (error) return <div className={newslettersStyles.error}>{error}</div>;

  const headers = [
    { key: 'subject', label: 'Subject' },
    { key: 'status', label: 'Status' },
    { key: 'createdAt', label: 'Created At' },
    { key: 'sentAt', label: 'Sent At' }
  ];

  const formattedNewsletters = newsletters.map(newsletter => ({
    ...newsletter,
    createdAt: newsletter.createdAt ? new Date(newsletter.createdAt).toLocaleDateString() : 'N/A',
    sentAt: newsletter.sentAt ? new Date(newsletter.sentAt).toLocaleDateString() : 'Not sent'
  }));

  return (
    <div className={newslettersStyles.newslettersContainer}>
      <div className={newslettersStyles.header}>
        <h1>Newsletters</h1>
        <button onClick={handleAddNewsletter} className={newslettersStyles.addButton}>
          Add Newsletter
        </button>
      </div>
      {newsletters.length > 0 ? (
        <Table 
          headers={headers} 
          data={formattedNewsletters} 
          keyField="_id" 
          onRowClick={handleRowClick}
        />
      ) : (
        <p>No newsletters found.</p>
      )}
    </div>
  );
}

export default Newsletters;