import React from 'react';
import { Button } from '@nextui-org/react';
import { Icon } from '@iconify/react';

const Toolbar = ({ onCreateItem, onFetchFacebookPosts }) => {
  return (
    <div className="toolbar flex space-x-2">
      {/* Existing Add New Post Button */}
      <Button onPress={onCreateItem} auto flat>
        <Icon icon="mdi:plus" width={20} />
        Add New Post
      </Button>
      {/* New Fetch Posts Button */}
      <Button onPress={onFetchFacebookPosts} auto flat>
        <Icon icon="mdi:facebook" width={20} />
        Fetch Posts
      </Button>
    </div>
  );
};

export default Toolbar;