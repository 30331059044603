// src/api/index.js

import axios from 'axios';
import { API_URL } from '../config'; // Import API_URL from config.js

// Create an Axios instance with the correct base URL
const api = axios.create({
  baseURL: API_URL, // Does not include '/api'
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to add Authorization header
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken'); // Ensure consistent token key
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle unauthorized responses globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authToken');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;