import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Textarea
} from "@nextui-org/react";
import { Icon } from '@iconify/react';
import api from '../api';

const Businesses = () => {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [newBusiness, setNewBusiness] = useState({
    name: '',
    description: '',
    zipCode: '',
  });
  const [editingBusiness, setEditingBusiness] = useState(null);

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const fetchBusinesses = async () => {
    try {
      const response = await api.get('/api/businesses');
      setBusinesses(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching businesses:', error);
      setError('Failed to fetch businesses');
      setLoading(false);
    }
  };

  const handleCreateBusiness = async () => {
    try {
      const response = await api.post('/api/businesses', newBusiness);
      setBusinesses([...businesses, response.data.data]);
      onCreateClose();
      setNewBusiness({ name: '', description: '', zipCode: '' });
    } catch (error) {
      console.error('Error creating business:', error);
      setError(error.response?.data?.message || 'Failed to create business');
    }
  };

  const handleEditBusiness = async () => {
    try {
      const response = await api.put(`/api/businesses/${editingBusiness._id}`, editingBusiness);
      setBusinesses(businesses.map(b => b._id === editingBusiness._id ? response.data.data : b));
      onEditClose();
    } catch (error) {
      console.error('Error updating business:', error);
      setError(error.response?.data?.message || 'Failed to update business');
    }
  };

  const handleDeleteBusiness = async (id) => {
    if (window.confirm('Are you sure you want to delete this business?')) {
      try {
        await api.delete(`/api/businesses/${id}`);
        setBusinesses(businesses.filter(b => b._id !== id));
      } catch (error) {
        console.error('Error deleting business:', error);
        setError(error.response?.data?.message || 'Failed to delete business');
      }
    }
  };

  const columns = [
    { name: "NAME", uid: "name" },
    { name: "DESCRIPTION", uid: "description" },
    { name: "ZIP CODE", uid: "zipCode" },
    { name: "ACTIONS", uid: "actions" }
  ];

  const renderCell = (business, columnKey) => {
    switch (columnKey) {
      case "name":
        return <div>{business.name}</div>;
      case "description":
        return <div>{business.description}</div>;
      case "zipCode":
        return <div>{business.zipCode}</div>;
      case "actions":
        return (
          <div className="flex items-center gap-4 justify-center">
            <Tooltip content="Edit business">
              <Button isIconOnly color="warning" variant="light" onClick={() => {
                setEditingBusiness(business);
                onEditOpen();
              }}>
                <Icon icon="mdi:pencil" width={20} />
              </Button>
            </Tooltip>
            <Tooltip content="Delete business" color="danger">
              <Button isIconOnly color="danger" variant="light" onClick={() => handleDeleteBusiness(business._id)}>
                <Icon icon="mdi:delete" width={20} />
              </Button>
            </Tooltip>
          </div>
        );
      default:
        return <div>N/A</div>;
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Businesses</h1>
        <Button onPress={onCreateOpen} color="primary">
          <Icon icon="mdi:plus" width={20} />
          Add New Business
        </Button>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="text-danger">{error}</div>
      ) : (
        <Table
          aria-label="Businesses Table"
          css={{ height: "auto", minWidth: "100%" }}
          selectionMode="none"
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody items={businesses}>
            {(item) => (
              <TableRow key={item._id}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}

      {/* Create Business Modal */}
      <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Create New Business</ModalHeader>
          <ModalBody>
            <Input
              label="Business Name"
              placeholder="Enter business name"
              value={newBusiness.name}
              onChange={(e) => setNewBusiness({ ...newBusiness, name: e.target.value })}
            />
            <Textarea
              label="Description"
              placeholder="Enter business description"
              value={newBusiness.description}
              onChange={(e) => setNewBusiness({ ...newBusiness, description: e.target.value })}
            />
            <Input
              label="Zip Code"
              placeholder="Enter zip code"
              value={newBusiness.zipCode}
              onChange={(e) => setNewBusiness({ ...newBusiness, zipCode: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onPress={onCreateClose}>
              Cancel
            </Button>
            <Button color="primary" onPress={handleCreateBusiness}>
              Create Business
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Business Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Edit Business</ModalHeader>
          <ModalBody>
            <Input
              label="Business Name"
              placeholder="Enter business name"
              value={editingBusiness?.name || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, name: e.target.value })}
            />
            <Textarea
              label="Description"
              placeholder="Enter business description"
              value={editingBusiness?.description || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, description: e.target.value })}
            />
            <Input
              label="Zip Code"
              placeholder="Enter zip code"
              value={editingBusiness?.zipCode || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, zipCode: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onPress={onEditClose}>
              Cancel
            </Button>
            <Button color="primary" onPress={handleEditBusiness}>
              Update Business
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Businesses;