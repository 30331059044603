import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import NewBusiness from './components/NewBusiness';
import Users from './components/Users';
import Businesses from './components/Businesses';
import Newsletters from './components/Newsletters';
import CollectionPosts from './components/CollectionPosts';
import { NextUIProvider } from '@nextui-org/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './styles/globals.css';

import SidebarWrapper from './components/Sidebar/SidebarWrapper';
import PrivateRoute from './components/PrivateRoute';
import PostEditor from './components/PostEditor'; // Add this line

function App() {
  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <NextUIProvider>
        <AuthProvider>
          <Router>
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />

              {/* Protected Routes */}
              <Route
                path="/*"
                element={
                  <PrivateRoute>
                    <SidebarWrapper />
                  </PrivateRoute>
                }
              >
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="users" element={<Users />} />
                <Route path="businesses" element={<Businesses />} />
                <Route path="newsletters" element={<Newsletters />} />
                <Route path="collection-posts" element={<CollectionPosts />} />
                <Route path="collection-posts/:collectionId" element={<PostEditor />} />
                {/* Add more nested routes as needed */}
              </Route>

              {/* Redirect any unknown routes */}
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
        </AuthProvider>
      </NextUIProvider>
    </GoogleOAuthProvider>
  );
}

export default App;