import React, { useState, useEffect } from 'react';
import { Avatar, Button, ScrollShadow, Spacer, Tooltip, cn } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { useMediaQuery } from "usehooks-ts";
import { Outlet, useNavigate } from "react-router-dom";

import Sidebar from "./sidebar";
import { items } from "./sidebar-items";
import { useAuth } from "../../hooks/useAuth";
import Logo from "./Logo"; 

export default function SidebarWrapper() {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const [isExpanded, setIsExpanded] = useState(!isMobile);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setIsExpanded(!isMobile);
  }, [isMobile]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className="flex h-screen w-full">
      {/* Sidebar Section */}
      <div
        className={cn(
          "relative flex h-full flex-col border-r border-divider p-6 transition-all duration-300 ease-in-out",
          {
            "w-16 items-center px-2 py-6": !isExpanded,
            "w-72": isExpanded,
          }
        )}
      >
        {/* Logo and App Name */}
        <div
          className={cn("flex items-center gap-3 px-3", {
            "justify-center gap-0": !isExpanded,
          })}
        >
          <Logo size={32} />
          {isExpanded && (
            <span className="text-small font-bold uppercase opacity-100">
              Erik's Local Biz List
            </span>
          )}
        </div>
        <Spacer y={8} />
        {/* User Info */}
        <div className="flex items-center gap-3 px-3">
          <Avatar
            isBordered
            className="flex-none"
            size="sm"
            src="https://i.pravatar.cc/150?u=a04258114e29026708c"
          />
          {isExpanded && user && (
            <div className="flex max-w-full flex-col">
              <p className="truncate text-small font-medium text-default-600">
                {user.email}
              </p>
              <p className="truncate text-tiny text-default-400">
                User
              </p>
            </div>
          )}
        </div>
        {/* Sidebar Navigation */}
        <ScrollShadow className="-mr-6 h-full max-h-full py-6 pr-6">
          <Sidebar isCompact={!isExpanded} items={items} />
        </ScrollShadow>
        <Spacer y={2} />
        {/* Logout and Help Buttons */}
        <div
          className={cn("mt-auto flex flex-col", {
            "items-center": !isExpanded,
          })}
        >
          <Tooltip content="Help & Information" isDisabled={isExpanded} placement="right">
            <Button
              className={cn(
                "justify-start text-default-500 hover:text-foreground",
                {
                  "justify-center": !isExpanded,
                }
              )}
              isIconOnly={!isExpanded}
              variant="light"
            >
              {!isExpanded ? (
                <Icon
                  className="text-default-500"
                  icon="solar:info-circle-line-duotone"
                  width={24}
                />
              ) : (
                <>
                  <Icon
                    className="flex-none text-default-500"
                    icon="solar:info-circle-line-duotone"
                    width={24}
                  />
                  <span className="ml-2">Help & Information</span>
                </>
              )}
            </Button>
          </Tooltip>
          <Tooltip content="Log Out" isDisabled={isExpanded} placement="right">
            <Button
              onPress={handleLogout}
              className={cn(
                "justify-start text-default-500 hover:text-foreground",
                {
                  "justify-center": !isExpanded,
                }
              )}
              isIconOnly={!isExpanded}
              variant="light"
            >
              {!isExpanded ? (
                <Icon
                  className="rotate-180 text-default-500"
                  icon="solar:minus-circle-line-duotone"
                  width={24}
                />
              ) : (
                <>
                  <Icon
                    className="flex-none rotate-180 text-default-500"
                    icon="solar:minus-circle-line-duotone"
                    width={24}
                  />
                  <span className="ml-2">Log Out</span>
                </>
              )}
            </Button>
          </Tooltip>
        </div>
      </div>
      {/* Main Content Area */}
      <div className="w-full flex-1 flex-col p-4">
        <main className="mt-4 h-full w-full overflow-visible">
          <Outlet />
        </main>
      </div>
    </div>
  );
}