import React, { useState, useRef, useEffect } from 'react';
import styles from './Table.module.css';

function Table({ headers, data, keyField, onRowClick, disableRowClick = false, columnWidths = {} }) {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [editValue, setEditValue] = useState('');
  const dropdownRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
      if (textareaRef.current && !textareaRef.current.contains(event.target)) {
        handleCellBlur();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [editValue]);

  const handleSelectClick = (e, rowId, field) => {
    e.stopPropagation();
    setOpenDropdown(openDropdown === `${rowId}-${field}` ? null : `${rowId}-${field}`);
  };

  const handleOptionSelect = (rowId, field, value) => {
    const header = headers.find(h => h.key === field);
    if (header?.onEdit) {
      header.onEdit(rowId, value);
    }
    setOpenDropdown(null);
  };

  const handleCellClick = (e, rowId, field, value) => {
    e.stopPropagation();
    if (headers.find(h => h.key === field)?.editable) {
      setEditingCell({ rowId, field });
      setEditValue(value);
    }
  };

  const handleCellBlur = () => {
    if (editingCell) {
      const header = headers.find(h => h.key === editingCell.field);
      if (header?.onEdit) {
        header.onEdit(editingCell.rowId, editValue);
      }
      setEditingCell(null);
    }
  };

  const handleCellKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleCellBlur();
    }
  };

  const renderCellContent = (header, content, rowId) => {
    if (header.type === 'select') {
      const isOpen = openDropdown === `${rowId}-${header.key}`;
      return (
        <div className={styles.selectWrapper}>
          <div 
            className={`${styles.selectButton} ${isOpen ? styles.active : ''}`}
            onClick={(e) => handleSelectClick(e, rowId, header.key)}
          >
            <span className={styles.selectButtonText}>{content}</span>
            <span className={styles.dropdownIndicator}>▼</span>
          </div>
          {isOpen && (
            <div className={styles.selectDropdown} ref={dropdownRef}>
              {header.options.map(option => (
                <div 
                  key={option} 
                  className={styles.selectOption}
                  onClick={() => handleOptionSelect(rowId, header.key, option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    } else if (header.editable) {
      return (
        <div className={styles.editableCell}>
          {editingCell?.rowId === rowId && editingCell?.field === header.key ? (
            <div className={styles.editingContainer}>
              <textarea
                ref={textareaRef}
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                onBlur={handleCellBlur}
                onKeyDown={handleCellKeyDown}
                className={styles.fullWidthInput}
                autoFocus
              />
            </div>
          ) : (
            <div 
              className={styles.cellContent}
              onClick={(e) => handleCellClick(e, rowId, header.key, content)}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
      );
    }
    return content;
  };

  const handleRowClick = (row) => {
    if (!disableRowClick && onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <colgroup>
          {headers.map(header => (
            <col key={header.key} style={{ width: columnWidths[header.key] || 'auto' }} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header.key} className={styles[`col-${header.key}`]}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <tr 
              key={row[keyField]} 
              onClick={() => handleRowClick(row)}
              className={!disableRowClick ? styles.clickableRow : ''}
            >
              {headers.map(header => (
                <td 
                  key={header.key} 
                  className={`${styles.tableCell} ${header.type === 'select' ? styles.selectCell : ''}`}
                >
                  {renderCellContent(header, row[header.key], row[keyField])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export { Table };  // Named export