import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, Button, Tooltip, useDisclosure, Checkbox, Divider, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import api from '../api';
import Toolbar from './Toolbar';
import PostForm from './PostForm';

const POST_TYPES = ['sale', 'promo', 'event', 'update'];

const PostEditor = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingPost, setEditingPost] = useState(null);
  const { collectionId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchPostsAndBusinesses();
  }, [collectionId]);

  const fetchPostsAndBusinesses = async () => {
    try {
      const postsResponse = await api.get(`/api/content-collections/${collectionId}/posts`);
      console.log('Raw posts data:', postsResponse.data);
      const postsData = postsResponse.data.data;

      if (!Array.isArray(postsData)) {
        throw new Error('Invalid posts data');
      }

      const postsWithBusinessNames = await Promise.all(
        postsData.map(async (post) => {
          const businessId = post.businessId?._id || post.businessId;

          if (businessId) {
            try {
              const businessResponse = await api.get(`/api/businesses/${businessId}`);
              return { ...post, businessName: businessResponse.data.data.name };
            } catch (error) {
              console.error(`Error fetching business for post ${post._id}:`, error);
              return { ...post, businessName: 'N/A' };
            }
          }
          return { ...post, businessName: 'N/A' };
        })
      );

      console.log('Fetched posts with business names:', postsWithBusinessNames);
      setPosts(postsWithBusinessNames);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to fetch posts');
      setLoading(false);
    }
  };

  const handleFetchFacebookPosts = async () => {
    try {
      setLoading(true);
      const response = await api.post(`/api/content-collections/${collectionId}/fetch-facebook-posts`);
      const newPosts = response.data.posts || [];

      // Fetch business names for new posts
      const postsWithBusinessNames = await Promise.all(
        newPosts.map(async (post) => {
          const businessId = post.businessId;
          if (businessId) {
            try {
              const businessResponse = await api.get(`/api/businesses/${businessId}`);
              return { ...post, businessName: businessResponse.data.data.name };
            } catch (error) {
              console.error(`Error fetching business for post ${post._id}:`, error);
              return { ...post, businessName: 'N/A' };
            }
          }
          return { ...post, businessName: 'N/A' };
        })
      );

      setPosts((prevPosts) => [...prevPosts, ...postsWithBusinessNames]);
    } catch (error) {
      console.error('Error fetching Facebook posts:', error);
      alert('Failed to fetch Facebook posts');
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePost = () => {
    setEditingPost({}); // Set to an empty object instead of null
    onOpen();
  };

  const handleEditPost = (post) => {
    // Find the business name for the post
    const businessName = post.businessName || 'N/A';
    setEditingPost({ ...post, businessName });
    onOpen();
  };

  const handleSubmitPost = async (formData) => {
    try {
      let updatedPost;
      if (formData._id) {
        console.log('Updating post:', formData);
        const response = await api.put(`/api/posts/${formData._id}`, formData);
        updatedPost = response.data.data;
      } else {
        console.log('Creating new post:', formData);
        const response = await api.post(`/api/content-collections/${collectionId}/posts`, formData);
        updatedPost = response.data.data;
      }

      // Fetch the business name for the new post
      if (updatedPost.businessId) {
        try {
          const businessResponse = await api.get(`/api/businesses/${updatedPost.businessId}`);
          updatedPost.businessName = businessResponse.data.data.name;
        } catch (error) {
          console.error('Error fetching business name:', error);
          updatedPost.businessName = 'N/A';
        }
      } else {
        updatedPost.businessName = 'N/A';
      }

      setPosts(prevPosts => {
        if (formData._id) {
          return prevPosts.map(post => post._id === formData._id ? updatedPost : post);
        } else {
          return [...prevPosts, updatedPost];
        }
      });

      onClose();
    } catch (error) {
      console.error('Error submitting post:', error.response?.data || error.message);
      alert(`Failed to submit post. Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      await api.delete(`/api/posts/${postId}`);
      setPosts(posts.filter(post => post._id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
      alert('Failed to delete post. Please try again.');
    }
  };

  const handleTypeChange = async (postId, value) => {
    try {
      const response = await api.put(`/api/posts/${postId}`, { type: value });
      const updatedPost = response.data.data;
      setPosts(prevPosts => 
        prevPosts.map(post => post._id === postId ? { ...post, type: updatedPost.type } : post)
      );
    } catch (error) {
      console.error('Error updating type:', error);
      alert('Failed to update type. Please try again.');
    }
  };

  const handleIncludeChange = async (postId, newIncludeValue) => {
    try {
      const response = await api.put(`/api/posts/${postId}`, { include: newIncludeValue });
      const updatedPost = response.data.data;
      setPosts(prevPosts => 
        prevPosts.map(post => post._id === postId ? { ...post, include: updatedPost.include } : post)
      );
    } catch (error) {
      console.error('Error updating include status:', error);
      alert('Failed to update include status. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="space-y-4">
      <Toolbar
        onCreateItem={handleCreatePost}
        onFetchFacebookPosts={handleFetchFacebookPosts}
      />
      {posts.map((post) => (
        <Card key={post._id} className="w-full">
          <CardBody className="space-y-2">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">{post.businessName || 'N/A'}</h3>
              <Checkbox 
                isSelected={post.include} 
                onValueChange={(isChecked) => handleIncludeChange(post._id, isChecked)}
              >
                Include
              </Checkbox>
            </div>
            <Divider className="my-1" />
            <div className="grid grid-cols-2 gap-2 text-sm">
              <Dropdown>
                <DropdownTrigger>
                  <Button variant="bordered" size="sm">
                    Type: {post.type || 'Select'}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu 
                  aria-label="Post Type Selection" 
                  onAction={(key) => handleTypeChange(post._id, key)}
                >
                  {POST_TYPES.map((type) => (
                    <DropdownItem key={type}>{type}</DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <div>
                <span className="font-semibold">AI Type:</span> {post.aiDeterminedType || 'N/A'}
              </div>
            </div>
            <Divider className="my-1" />
            <div className="text-sm">
              <p className="font-semibold mb-1">Raw Biz Content:</p>
              <p className="whitespace-pre-wrap">{post.rawBizContent || 'N/A'}</p>
            </div>
            <Divider className="my-1" />
            <div className="text-sm">
              <p className="font-semibold mb-1">AI Output:</p>
              <p className="whitespace-pre-wrap">{post.aiOutput || 'N/A'}</p>
            </div>
            <Divider className="my-1" />
            <div className="text-sm">
              <p className="font-semibold mb-1">Final Copy:</p>
              <p className="whitespace-pre-wrap">{post.finalCopy || 'N/A'}</p>
            </div>
          </CardBody>
          <CardFooter className="justify-end space-x-2">
            <Button color="primary" variant="light" size="sm" onPress={() => handleEditPost(post)}>
              Edit
            </Button>
            <Tooltip content="Delete post">
              <Button
                isIconOnly
                color="danger"
                variant="light"
                size="sm"
                onPress={() => handleDeletePost(post._id)}
              >
                <Icon icon="mdi:trash-can-outline" width={18} />
              </Button>
            </Tooltip>
          </CardFooter>
        </Card>
      ))}

      <PostForm
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmitPost}
        initialData={editingPost || {}}
      />
    </div>
  );
};

export default PostEditor;