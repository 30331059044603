import React from 'react';
import styles from './Dashboard.module.css';

const Dashboard = () => {
  return (
    <div className={styles.dashboardContainer}>
      {/* Dashboard content will go here */}
    </div>
  );
};

export default Dashboard;