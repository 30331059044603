import React from "react";

const Logo = ({ size = 50, ...props }) => (
  <img
    src="https://localbizlistbucket.s3.amazonaws.com/Erik%E2%80%99s+(Logo).svg"
    alt="Your Logo"
    width={size}
    height={size}
    {...props}
  />
);

export default Logo;